import React, {useState, useEffect} from "react"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-input-range/lib/css/index.css'
import SEO from "../../components/seo"

import Header from  "../../components/Common/Header"; 
import NavLink from  "../../components/NavLink/Custom_Presentation"; 
import {config} from '../../components/Common/constant';

import PropertyLaunch from "../../components/modules/property_launch"

import MarketingAnalytics from "../../components/modules/marketing_analytics"
import { GET_ARTICLE } from "../../queries/common_use_query";

const PresentationPage = () => {

    const [ article_data, setArticleData ] = useState('');
    const { loading, error, data } = GET_ARTICLE('introducing-property-launch');

    useEffect(()=>{
        setArticleData(data && data.articles[0]);
    }, [data]);

    return <>
        <SEO title={article_data && article_data.Meta_Title} description={article_data && article_data.Meta_Description} />
        
        <div className="pre-wrapper">

        <Header />

        <div className="section-wrapper custom_present_inner pt-0">
            <PropertyLaunch presentation_type="custom" />

            <MarketingAnalytics presentation_type="custom" />
        </div>
        
        
        <NavLink tab_name="Launch" prevLink={config.custom_casestudies}  nextLink={config.custom_prop_datetime} />

    </div>
    </>
}

export default PresentationPage